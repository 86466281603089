import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Flex, Heading } from 'rebass/styled-components'

import Layout from '../layouts'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Section from '../components/section'
import Container from '../components/container'
import ProductCard from '../components/product/card'
import { OutlineLink } from '../components/button'

const IndexPage = props => {
  const { data, children } = props
  const { edges: products } = data.allMarkdownRemark

  return (
    <Layout {...props} locale="en">
      <SEO title={data.site.siteMetadata.description} />
      <Hero
        fluid={data.file.childImageSharp.fluid}
        alt={data.site.siteMetadata.title}
        maxWidth="1440px"
        fullHeight
        showLogo
      />

      <Section>
        <Container>
          <Heading as="h2" mb={[2, 3, 4]}>
            <FormattedMessage id="homepage.bestSellers" defaultMessage="Best Sellers" />
          </Heading>

          {products && (
            <Flex flexWrap="wrap" justifyContent="space-between">
              {products.map(productNode => {
                const product = productNode.node
                const { id, excerpt, frontmatter } = product
                const {
                  slug,
                  sku,
                  title,
                  featuredImage,
                  currency,
                  price,
                  video,
                  inStock,
                } = frontmatter

                return (
                  <Flex key={id} width={['100%', '48%', '30%']} mb={4}>
                    <ProductCard
                      slug={`/product/${slug}`}
                      sku={sku}
                      title={title}
                      excerpt={excerpt}
                      image={featuredImage}
                      currency={currency}
                      price={price}
                      video={video}
                      inStock={inStock}
                    />
                  </Flex>
                )
              })}
            </Flex>
          )}

          <Flex justifyContent="center" mb={5}>
            <OutlineLink to="/collection/">Check out our collection</OutlineLink>
          </Flex>
        </Container>

        <Container maxWidth='780px'>
          {children}
        </Container>
      </Section>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
  children: PropTypes.array,
}

export default IndexPage
