import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Text } from 'rebass'

export const InStock = ({ inStock }) => {
  return (
    <>
      {inStock && (
        <Text color="green" mb={3}>
          <FormattedMessage id="product.inStock" defaultMessage="In stock" />
        </Text>
      )}

      {!inStock && (
        <Text color="orange" mb={3}>
          <FormattedMessage
            id="product.madeToOrder"
            defaultMessage="Made to order"
          />
        </Text>
      )}
    </>
  )
}

InStock.propTypes = {
  inStock: PropTypes.bool,
}

export default InStock
